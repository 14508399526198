<template>
  <div class="chat-container">
    <b-container fluid class="mb-5">
      <b-row>
        <b-col>
          <div class="messages">
            <template v-for="(message, index) in messages">
              <div :key="index" :class="['message', message.role]">
                <span v-if="message.parsedContent" v-html="message.parsedContent"></span>
                <span v-else>{{ message.content }}</span>
                <b-dropdown variant="outline-secondary" class="inline-block float-right ml-2" no-caret right>
                  <template #button-content>
                    <font-awesome-icon icon="sticky-note" :style="{ color: 'black' }"/>
                  </template>
                  <b-dropdown-item @click="saveMessageToNote(message)">Save to note</b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showSystemNotes" class="bg-light m-1 pt-1 rounded">
        <b-col>
          <b-form-group>
            <label for="system-notes">Select a system message:</label>
            <b-form-select id="system-notes" v-model="systemMessage.content">
              <option :value="defaultSystemMessage.content">{{ defaultSystemMessage.content }}</option>
              <option v-for="note in systemNotes" :key="note.id" :value="note.content">{{ note.content }}</option>
            </b-form-select>
            <label for="attachment" class="mt-2">Select an attachement:</label>
            <b-form-select id="attachment" v-model="attachement">
              <option :value="null">No attachment</option>
              <option v-for="note in contentOnlyNotes" :key="note.id" :value="note.content">{{ note.title }} ({{ note.content.length }})</option>
            </b-form-select>
            <label for="command" class="mt-2">Select a command:</label>
            <div class="form-inline sub-mr-2">
              <b-form-select id="command" v-model="command">
                <option :value="null">No command</option>
                <option v-for="note in commandNotes" :key="note.id" :value="note.content">{{ note.title }}</option>
              </b-form-select>
              <b-button @click="runCommand" variant="success">Run Command</b-button>
            </div>
            <label for="language-model" class="mt-2">Select a language model:</label>
            <b-form-select id="language-model" v-model="languageModel">
              <option v-for="model in languageModels" :key="model" :value="model">{{ model }}</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form @submit.prevent="sendMessage">
            <b-input-group>
              <b-input-group-prepend>
                <b-button variant="outline-secondary" @click="showSystemNotes = !showSystemNotes">
                  <font-awesome-icon icon="gear" :style="{ color: 'black' }"/>
                </b-button>
              </b-input-group-prepend>
              <b-form-input v-model="inputMessage" placeholder="Type your message"></b-form-input>
                <b-input-group-append>
                  <b-dropdown variant="outline-secondary" class="ml-2" no-caret right>
                        <template #button-content>
                          <font-awesome-icon icon="sticky-note" :style="{ color: 'black' }"/>
                        </template>
                    <b-dropdown-item @click="saveToNote">Save to note</b-dropdown-item>
                    <b-dropdown-item v-for="note in commandNotes" :key="note.id" :value="note.content" @click="loadPrompt(note)">{{ note.content }}</b-dropdown-item>
                  </b-dropdown>
                  <b-button type="submit" variant="primary" :disabled="loading">
                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-else>Send</span>
                  </b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('chat');
import { callCompletionApiFetch } from "./chatgpt";
import marked from "marked";

export default {
  data() {
    return {
      config: null,
      defaultSystemMessage: {
        role: "system",
        content: "You are a helpful assistant. Please reply in Markdown."
      },
      systemMessage: {},
      attachement: null,
      command: null,
      messages: [], // Store messages in an array
      inputMessage: "", // Store user input message
      showSystemNotes: false, // Add a new data property to track whether to show system notes or not
      loading: false,
      languageModels: ["gpt-3.5-turbo", "gpt-4"],
      languageModel: "gpt-3.5-turbo"
    };
  },
  computed: {
    notes() { return this.$store.state.notes; },
    systemNotes() {
      return this.notes.filter((note) => note.tags.includes("system"));
    },
    commandNotes() {
      return this.notes.filter((note) => note.tags.includes("Command"));
    },
    contentOnlyNotes() {
      return this.notes.filter((note) => note.tags.length == 0);
    },
    promptNotes() {
      return this.notes.filter((note) => note.tags.includes("Prompt"));
    }
  },
  mounted() {
    this.systemMessage = {...this.defaultSystemMessage};
    this.init();
  },
  methods: {
    async init() {
      this.config = await this.$store.getConfig();
      log.log("Config:", this.config);
    },
    async runCommand() {
      this.inputMessage = this.command.replace("(content)", this.attachement);
    },
    async sendMessage() {
      this.loading = true;
      // Add user message to messages array
      this.messages.push({ content: this.inputMessage, role: "user" });
      callCompletionApiFetch(this.config, {
        messages: this.messages.map(({ role, content }) => ({ role, content })),
        systemMessage: this.systemMessage,
        model: this.languageModel,
      }, (result) => {
        //log.log("Result:", result);
        const parsedContent = marked.parse(result);
        this.messages.push({content: result, parsedContent, role:"assistant"});
        this.lastMessage = this.messages.length - 1;
        log.log("Last message:", this.lastMessage);
        this.inputMessage = "";
      }, (updatedResult) => {
        //log.log("Updated result:", updatedResult);
        const parsedContent = marked.parse(updatedResult);
        this.$set(this.messages, this.lastMessage, {content: updatedResult, parsedContent, role:"assistant"});
      }, () => {
        this.loading = false;
      });
    },
    async saveToNote() {
      // Create a new note object with the input message as content and an empty array of tags
      const newNote = { content: this.inputMessage, tags: ['prompt'] };
      // Add the new note to the Vuex store
      this.$store.addNote(newNote);
      // Clear the input message
      this.inputMessage = "";
    },
    loadPrompt(note) {
      this.inputMessage = note.content;
    },
    saveMessageToNote(message) {
      // Create a new note object with the input message as content and an empty array of tags
      const newNote = { title: this.messages[0].content, content: message.content, tags: [] };
      // Add the new note to the Vuex store
      this.$store.addNote(newNote);
    }
  },
};
</script>

<style scoped>
.chat-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messages {
  overflow-y: auto;
  max-height: 80vh;
  padding: 1rem;
}

.message {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  max-width: 70%;
  display: inline-block;
  clear: both;
}

.message.user {
  background-color: #007bff;
  color: white;
  float: right;
  clear: both;
}

.message.system,
.message.assistant {
  background-color: #f8f9fa;
  color: #212529;
  float: left;
  clear: both;  
}
</style>
