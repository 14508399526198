import { getLog } from '@/services/log';
let log = getLog('chatgpt', false);
import axios from "axios";

export async function callCompletionApiAxios(config, params, onResult) {
    let response = null;
    try {
        response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: "gpt-3.5-turbo",
                messages: [
                    params.systemMessage,
                    ...params.messages,
                ],
                stream: false,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${config.key}`,
                },
            }
            );
        } catch (error) {
            log.error(error);
        }
        // Add GPT-3.5-turbo response to messages array
        onResult(response.data.choices[0].message);
    }

/**
 * Calls the OpenAI Completion API using Fetch.
 * @param {Object} config - Configuration object containing the API key.
 * @param {Object} params - Parameters object containing the system message and messages array.
 * @param {Function} onResult - Callback function to handle the API response.
 * @param {Function} onResultUpdate - Callback function to handle updates to the API response.
 * @param {Function} onResultComplete - Callback function to handle the completion of the API response.
 * @returns {Promise} - Promise object representing the API call.
 */
export async function callCompletionApiFetch(config, params, onResult, onResultUpdate, onResultComplete) {
    // The promise returned by `fetch` rejects if the fetch was unable to make HTTP-request
    //  e.g. network problems, or there’s no such site.
    // Abnormal HTTP-statuses, such as 404 or 500 do not cause an error.
    fetch("https://api.openai.com/v1/chat/completions", {
        method: 'post', // HTTP POST to send query to server
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${config.key}`,
        },
        body: JSON.stringify({
            model: params.model || "gpt-3.5-turbo",
            messages: [
                params.systemMessage,
                ...params.messages,
            ],
            stream: true,
        }), // server is expecting JSON
    }).then(res => {
        const reader = res.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
      
        function read() {
          reader.read().then(({ value }) => {
            let val = decoder.decode(value, { stream: true });
            let vals = val.split('data: ');
            log.log("vals", vals);
            for (let val of vals) {
                if (!val)
                    continue;
                else if (val.trim() == "[DONE]") {
                    onResultComplete();
                    continue;
                }
                log.log(`val '${val}'`);
                let data = JSON.parse(val);
                log.log("data", data);
                let content = data.choices[0].delta.content;
                log.log("content", content);
                if (content) {
                    if (!buffer) {
                        buffer += content;
                        onResult(buffer);
                    } else {
                        buffer += content;
                        onResultUpdate(buffer);
                    }
                    read();
                }
            }
          });
        }
        read();
    })
    .catch((error) => {
        // catches error case and if fetch itself rejects
        error.response = {
            status: 0,
            statusText:
            'Cannot connect. Please make sure you are connected to internet.',
        };
        throw error;
    });
}